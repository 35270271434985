import React, { useState } from 'react';
import { bool, oneOfType, object, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  LISTING_STATE_DRAFT,
  propTypes,
} from '../../util/types';
import { NamedLink } from '../../components';
import EditIcon from './EditIcon';

import css from './ListingPage.module.css';

export const ActionBarMaybe = props => {
  const { rootClassName, className, isOwnListing, listing, editParams, isCompany, closeListing, openListing } = props;
  const classes = classNames(rootClassName || css.actionBar, className);
  const state = listing.attributes.state;
  const isPendingApproval = state === LISTING_STATE_PENDING_APPROVAL;
  let isClosed = state === LISTING_STATE_CLOSED;
  const isDraft = state === LISTING_STATE_DRAFT;

  const [checkClosed, setCheckClosed] = useState(isClosed)
  if (isOwnListing) {
    let ownListingTextTranslationId = 'ListingPage.ownListing';

    if (isPendingApproval) {
      ownListingTextTranslationId = 'ListingPage.ownListingPendingApproval';
    } else if (checkClosed) {
      ownListingTextTranslationId = 'ListingPage.ownClosedListing';
    } else if (isDraft) {
      ownListingTextTranslationId = 'ListingPage.ownListingDraft';
    }

    const message = isDraft ? 'ListingPage.finishListing' : isCompany ? 'ListingPage.editListingCTA' : 'ListingPage.editListing';

    const ownListingTextClasses = classNames(css.ownListingText, {
      [css.ownListingTextPendingApproval]: isPendingApproval,
    });
    const parmaToSend = (editParams && editParams.id) ? editParams.id : null;

    return (
      <div className={classes}>
        <p className={ownListingTextClasses}>
          <FormattedMessage id={ownListingTextTranslationId} />
        </p>
        <div className={css.rightLayout}>
          <NamedLink className={css.editListingLink} name="EditListingPage" params={editParams}>
            <EditIcon className={css.editIcon} />
            <FormattedMessage id={message} />
          </NamedLink>

          {!isDraft ? <p className={css.closeListing} onClick={() => {
            checkClosed ? openListing(parmaToSend).then(() => {
              setCheckClosed(!checkClosed);
            }) : closeListing(parmaToSend).then(() => {
              setCheckClosed(!checkClosed);
            })
          }}>
            {checkClosed ? 'Open Listing' : 'Close Listing'}
          </p> : null}
        </div>



      </div>
    );
  } else if (isClosed) {
    return (
      <div className={classes}>
        <p className={css.closedListingText}>
          <FormattedMessage id="ListingPage.closedListing" />
        </p>
      </div>
    );
  }
  return null;
};
ActionBarMaybe.defaultProps = {
  rootClassName: null,
  className: null,
};

ActionBarMaybe.propTypes = {
  rootClassName: string,
  className: string,
  isOwnListing: bool.isRequired,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]).isRequired,
  editParams: object.isRequired,
};

ActionBarMaybe.displayName = 'ActionBarMaybe';

export default ActionBarMaybe;
